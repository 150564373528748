import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const GaugeChart = ({ value, label }) => {
  return (
    <div style={{ width: '100%', textAlign: 'center', position: 'relative' }}>
      <div style={{ width: 150, height: 150, margin: '0 auto' }}>
        <CircularProgressbar
          value={value}
          text={`${value.toFixed(2)}%`}
          styles={buildStyles({
            rotation: 1,
            strokeLinecap: 'butt',
            textSize: '14px',
            pathTransitionDuration: 0.5,
            pathColor: `rgba(11, 29, 81, ${value / 100})`,
            textColor: '#000000',
            trailColor: '#d6d6d6',
            backgroundColor: '#3e98c7',
          })}
        />
      </div>
    </div>
  );
};

export default GaugeChart;
