// File: src/pages/inicio.js

import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/inicio.module.css';
import useFetch from "../hooks/useFetch";
import GaugeChart from "../components/GaugeChart";

function Inicio() {
  const { data, execute } = useFetch();
  const executeRef = useRef(execute);
  const [systemInfo, setSystemInfo] = useState({
    cpuUsage: [],
    memoryUsage: { total: 0, free: 0, used: 0 },
    diskUsage: { total: 0, free: 0, used: 0 },
  });

  const fetchApiData = async (url, executeRef) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
    try {
      await executeRef.current(`${apiUrl}${url}`, "GET");
    } catch (error) {
      console.error("Error al realizar la petición:", error);
    }
  };

  useEffect(() => {
    fetchApiData("/server/systeminfo", executeRef);
    const intervalId = setInterval(() => {
      fetchApiData("/server/systeminfo", executeRef);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [executeRef]);

  useEffect(() => {
    if (data) {
      setSystemInfo(data);
    }
  }, [data]);

  const averageCpuUsage = systemInfo.cpuUsage.length
    ? systemInfo.cpuUsage.reduce((acc, val) => acc + val, 0) / systemInfo.cpuUsage.length
    : 0;

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>
        Dashboard
      </h1>
      <div className={styles.charts}>
        <div className={styles.chartContainer}>
          <span className={styles.blinkingdot}></span>
          <span className={styles.realtimeText}>En tiempo real</span>
          <h2>CPU Usage</h2>
          <GaugeChart value={averageCpuUsage * 100} label={`${(averageCpuUsage * 100).toFixed(2)}%`} />
        </div>

        <div className={styles.chartContainer}>
          <span className={styles.blinkingdot}></span>
          <span className={styles.realtimeText}>En tiempo real</span>
          <h2>Memory Usage</h2>
          <GaugeChart value={(systemInfo.memoryUsage.used / systemInfo.memoryUsage.total) * 100} label={`${((systemInfo.memoryUsage.used / systemInfo.memoryUsage.total) * 100).toFixed(2)}% Used`} />
        </div>

        <div className={styles.chartContainer}>
          <span className={styles.blinkingdot}></span>
          <span className={styles.realtimeText}>En tiempo real</span>
          <h2>Disk Usage</h2>
          <GaugeChart value={(systemInfo.diskUsage.used / systemInfo.diskUsage.total) * 100} label={`${((systemInfo.diskUsage.used / systemInfo.diskUsage.total) * 100).toFixed(2)}% Used`} />
        </div>
      </div>
    </div>
  );
}

export default Inicio;
