import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/gestion.module.css';
import useFetch from "../hooks/useFetch";

function GestionAPP() {
    const { data, execute } = useFetch();
    const { execute: executeEmpresa } = useFetch();
    const executeRef = useRef(execute);
    const executeEmpresaRef = useRef(executeEmpresa);
    const [empresas, setEmpresas] = useState([]);
    const navigate = useNavigate();
    const [nuevaEmpresa, setNuevaEmpresa] = useState({
        nombre: '',
        cif: '',
        direccion: '',
        ciudad: '',
        codigo_postal: '',
        provincia: '',
        pais: '',
        empleados: '',
        demo: 0,
        logo: 'https://reviventum-imagesprofile.s3.eu-west-3.amazonaws.com/empresa-63-logo_500x500.jpg'
    });

    const fetchApiData = async (url, executeRef) => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://api.feelinback.com";
        try {
            await executeRef.current(`${apiUrl}${url}`, "GET");
        } catch (error) {
            console.error("Error al realizar la petición:", error);
        }
    };

    const fetchApiDataPost = async (url, executeRef, body) => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://api.feelinback.com";
        try {
            await executeRef.current(`${apiUrl}${url}`, "POST", body);
        } catch (error) {
            console.error("Error al realizar la petición:", error);
        }
    };

    useEffect(() => {
        fetchApiData("/backoffice/empresas", executeRef);
    }, []);

    useEffect(() => {
        if (data) {
            setEmpresas(data);
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNuevaEmpresa({ ...nuevaEmpresa, [name]: value });
    };

    const handleAddEmpresa = () => {
        setEmpresas([...empresas, nuevaEmpresa]);
        setNuevaEmpresa({
            nombre: '',
            cif: '',
            direccion: '',
            ciudad: '',
            codigo_postal: '',
            provincia: '',
            pais: '',
            empleados: '',
            demo: 0,
            logo: ''
        });
        fetchApiDataPost("/backoffice/crear-empresa", executeEmpresaRef, nuevaEmpresa);
    };

    const handleGestionClick = (empresa) => {
        navigate(`/empresa/${empresa.id_empresa}`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.listSection}>
                <h2>Lista de Empresas</h2>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Logo</th>
                            <th>Nombre</th>
                            <th>CIF</th>
                            <th>Empleados</th>
                            <th>Demo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {empresas.map((empresa, index) => (
                            <tr key={index}>
                                <td><img src={empresa.logo} alt="logo" className={styles.empresaLogo} /></td>
                                <td>{empresa.nombre}</td>
                                <td>{empresa.cif}</td>
                                <td>{empresa.empleados}</td>
                                <td>{empresa.demo ? 'Sí' : 'No'}</td>
                                <td>
                                    <button onClick={() => handleGestionClick(empresa)} className={styles.gestionButton}>Gestionar</button>
                                    
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={styles.formSection}>
                <h2>Crear Nueva Empresa</h2>
                <div className={styles.dosinputshorizontal}>
                <input
                    type="text"
                    name="nombre"
                    value={nuevaEmpresa.nombre}
                    onChange={handleChange}
                    placeholder="Nombre de la empresa"
                    className={styles.inputGestion}
                />
                <input
                    type="text"
                    name="cif"
                    value={nuevaEmpresa.cif}
                    onChange={handleChange}
                    placeholder="CIF"
                    className={styles.inputGestion}
                />
                </div>
                <div className={styles.dosinputshorizontal}>
                <input
                    type="text"
                    name="direccion"
                    value={nuevaEmpresa.direccion}
                    onChange={handleChange}
                    placeholder="Dirección"
                    className={styles.inputGestion}
                />
                <input
                    type="text"
                    name="ciudad"
                    value={nuevaEmpresa.ciudad}
                    onChange={handleChange}
                    placeholder="Ciudad"
                    className={styles.inputGestion}
                />
                </div>
                <div className={styles.dosinputshorizontal}>
                <input
                    type="text"
                    name="codigo_postal"
                    value={nuevaEmpresa.codigo_postal}
                    onChange={handleChange}
                    placeholder="Código Postal"
                    className={styles.inputGestion}
                />
                <input
                    type="text"
                    name="provincia"
                    value={nuevaEmpresa.provincia}
                    onChange={handleChange}
                    placeholder="Provincia"
                    className={styles.inputGestion}
                />
                </div>
                <div className={styles.dosinputshorizontal}>
                <input
                    type="text"
                    name="pais"
                    value={nuevaEmpresa.pais}
                    onChange={handleChange}
                    placeholder="País"
                    className={styles.inputGestion}
                />
                <input
                    type="number"
                    name="empleados"
                    value={nuevaEmpresa.empleados}
                    onChange={handleChange}
                    placeholder="Número de empleados"
                    className={styles.inputGestion}
                />
                </div>
                <button onClick={handleAddEmpresa} className={styles.addButton}>Agregar Empresa</button>
            </div>
        </div>
    );
}

export default GestionAPP;
